<template>
	<div>
		<el-row>
			<div style="display: inline-block; width: 100%; height: 100%">
				<div class="child-top" style="padding-top: 0">
					<div class="input-from">
						<el-date-picker
							v-model="date"
							type="daterange"
							align="right"
							unlink-panels
							format="yyyy-MM-dd"
							value-format="yyyy-MM-dd"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							@change="getData(1)"
							class="picker_date"
							:picker-options="shortcutsPickerOptions"
						>
						</el-date-picker>
						<el-cascader
							@change="getStaffList"
							placeholder="请选择运动队"
							clearable
							style="width: 200px"
							v-model="deparmentId"
							:options="departmentOptions"
							:show-all-levels="false"
							collapse-tags
							:props="{ children: 'children', label: 'name', value: 'uuid', emitPath: false, multiple: true }"
						></el-cascader>
						<el-select v-model="staffValue" placeholder="请选择运动员" @change="getData(1)" clearable>
							<el-option v-for="item in staffList" :key="item.uuid" :label="item.name" :value="item.uuid"> </el-option>
						</el-select>
						<el-input
							class="left-child-input left-child-input-content"
							clearable
							placeholder="请输入报告名称"
							style="width: 200px"
							suffix-icon="iconfont iconsousuo"
							v-model="keyword"
							@keyup.enter.native="getData(1)"
						></el-input>
						<el-button class="searchBtn" round @click="getData(1)">搜索</el-button>
					</div>

					<div class="operation-button">
						<el-button class="del_btn" round @click="someDels()" :disabled="!sels.length">
							<!-- $store.getters.permissionsStr('删除') ||  -->
							<span>删除</span>
						</el-button>
					</div>
				</div>

				<div class="content-flex-pages" style="background: #fff">
					<!-- :data="!$store.getters.permissionsStr('列表') ? tableData : []" -->

					<el-table :empty-text="tableData.length ? '' : '暂无数据'" :data="tableData" class="content-test-table" style="width: 100%" v-loading="pictLoading" @selection-change="selsChange">
						<el-table-column type="selection" width="45"></el-table-column>
						<el-table-column label="序号" type="index" width="45">
							<template slot-scope="scope">{{ (page - 1) * limit + scope.$index + 1 }}</template>
						</el-table-column>
						<el-table-column label="报告名称" prop="title" show-overflow-tooltip></el-table-column>
						<el-table-column label="运动队" prop="department_name" show-overflow-tooltip></el-table-column>
						<el-table-column label="运动员" prop="staff_name" show-overflow-tooltip></el-table-column>
						<el-table-column label="创建人" prop="create_by" show-overflow-tooltip></el-table-column>
						<el-table-column label="创建时间" prop="create_time" show-overflow-tooltip></el-table-column>

						<el-table-column label="操作" width="120">
							<template slot-scope="scope">
								<!-- :disabled="$store.getters.permissionsStr('编辑')"  -->
								<el-button class="right20" style="color: #1a9f16" size="medium" type="text" @click="postLook(scope.row)" v-if="scope.row.uuid">查看</el-button>
								<el-button @click="delTableData(scope.row)" class="redColor" size="medium" type="text" v-if="scope.row.uuid">删除</el-button>
								<!-- :disabled="$store.getters.permissionsStr('删除')" -->
							</template>
						</el-table-column>
					</el-table>
					<el-pagination
						:current-page.sync="page"
						:page-size="limit"
						:total="total"
						:key="elementui_page_component_key"
						@current-change="handleCurrentChange"
						class="table-pagination"
						layout="total, prev, pager, next , jumper"
					></el-pagination>
				</div>
			</div>
		</el-row>
		<el-row>
			<!--    新增对话框-->
			<el-dialog :close-on-click-modal="false" :title="addMenuTitle" :visible.sync="dialogVisible" center top="10vh" width="700px" :show-close="false">
				<!--      新增详情-->
				<el-form label-position="left" label-width="82px" ref="ruleForm" style="margin-bottom: 20px" :inline="true">
					<el-form-item label="伤病部位名称" style="margin-bottom: 20px" required>
						<el-input placeholder="请输入名称" v-model="formAdd.name"></el-input>
					</el-form-item>
					<el-form-item label="顺序" style="margin-bottom: 20px" required>
						<el-input placeholder="请输入顺序" v-model="formAdd.order_no"></el-input>
					</el-form-item>
					<!--                新增编辑备注-->
					<el-form-item label="备注">
						<el-input :rows="4" :span="24" placeholder="请输入内容" v-model="formAdd.demo" type="textarea" style="display: block; width: 450px"></el-input>
					</el-form-item>
				</el-form>
				<div class="clearfix"></div>
				<div class="form-bottom-button clearfix">
					<!-- 编辑的 保存-->
					<el-button type="primary" v-no-more-click class="save_btn" round @click="submitAdd()">保 存</el-button>

					<el-button class="cancel_btn" round @click="resetForm()">取 消</el-button>
				</div>
			</el-dialog>
		</el-row>
	</div>
</template>

<script>
export default {
	data() {
		return {
			shortcutsPickerOptions: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
							picker.$emit('pick', [start, end])
						},
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
							picker.$emit('pick', [start, end])
						},
					},
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
							picker.$emit('pick', [start, end])
						},
					},
					{
						text: '最近一年',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setFullYear(start.getFullYear() - 1)
							picker.$emit('pick', [start, end])
						},
					},
				],
			},
			date: [],
			pictLoading: false,
			tableLoading: false,
			activeName: '2',
			dialogVisible: false,
			addMenuTitle: '',
			tabType: 2,
			sels: [], // 列表选中列
			total: 0, // 总数
			page: 1, // 当前分页
			limit: 10, // 每页条数
			tableData: [], // 表格数据
			formSearch: {
				status: '',
				name: '',
			},
			tabList: [],
			formAdd: {
				uuid: 0,
				name: '',
				order_no: '',
				demo: '',
			},
			formAddSet: {},
			handleType: 1,
			keyword: '',
			departmentOptions: [],
			staffList: [],
			deparmentId: [],
			staffValue: '',
			elementui_page_component_key: 0,
		}
	},
	created() {
		if (this.$route.query.page) {
			this.$set(this, 'page', Number(this.$route.query.page))
		}
	},
	mounted() {
		var obj = JSON.parse(sessionStorage.getItem('arch_search'))
		if (obj) {
			this.date = obj.date
			this.deparmentId = obj.deparmentId
			this.keyword = obj.keyword
			this.page = obj.page
			this.getStaffList()
			this.staffValue = obj.staffValue
			this.elementui_page_component_key++
		}

		this.formAddSet = { ...this.formAdd }

		if (this.$route.query.page) {
			this.getData(this.page)
		} else {
			this.getData(1)
		}

		// 获取运动队
		this.$axios.post('/p/staff/tree').then(res => {
			if (res.data.code == 0) {
				this.departmentOptions = res.data.data.department
			} else {
				this.$message({
					type: 'error',
					message: res.data.message,
				})
			}
		})
	},
	methods: {
		getPageSize() {
			var num = Math.ceil(this.total / 10)
			this.page = num
		},
		getStaffList() {
			this.staffValue = ''
			if (!this.deparmentId.length) {
				this.$set(this, 'staffList', [])
				return false
			}
			return new Promise(() => {
				this.$axios
					.post('/p/staff/lists', {
						department_uuid_str: this.deparmentId.toString(),
					})
					.then(res => {
						if (res.data.code == 0) {
							this.staffList = res.data.data.rows
							this.getData()
						} else {
							this.$message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
			})
		},
		handleClick(tab, event) {},
		resetForm() {
			this.formAdd = { ...this.formAddSet }
			this.dialogVisible = false
			return
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`)
		},
		selsChange(sels) {
			this.sels = sels
		},
		handleCurrentChange(val) {
			this.page = val
			this.getData()
		},
		//列表
		getData(run_type = 0) {
			if (run_type == 1) {
				this.page = 1
			}
			let params = {
				page: this.page,
				page_size: this.limit,
				start_date: this.date ? this.date[0] : '',
				end_date: this.date ? this.date[1] : '',
				department_uuid: this.deparmentId.toString(),
				staff_uuid: this.staffValue,
				keyword: this.keyword,
			}
			let url = 'p/analyse/lists'
			const self = this
			this.$axios.post(url, params).then(res => {
				self.pictLoading = false
				self.tableData = res.data.data.rows
				self.total = res.data.data.total
			})
		},
		// 删除影片
		handleDelete(id) {
			this.$confirm('确认删除吗？<br />', '提示', {
				type: 'warning',
				dangerouslyUseHTMLString: true,
			})
				.then(() => {
					let self = this
					let params = {
						id: id,
					}
					let url = `${baseUrl}/Hairservice/videoDel`
					axios.post(url, params).then(function(res) {
						console.log(res.data)
					})
				})
				.catch(() => {})
		},
		submitAdd() {
			let params = this.formAdd
			if (params.name == '') {
				this.$message({
					message: '伤病部位名称不能为空',
					type: 'error',
					duration: 4000,
				})
				return
			}
			if (params.name.length > 40) {
				this.$message({
					message: '伤病部位名称最大长度40',
					type: 'error',
					duration: 4000,
				})
				return
			}
			if (params.order_no === '') {
				this.$message({
					message: '顺序不能为空',
					type: 'error',
					duration: 4000,
				})
				return
			}

			if (isNaN(Number(params.order_no))) {
				this.$message({
					message: '顺序只能输入数字',
					type: 'error',
					duration: 4000,
				})
				return
			}
			if (params.order_no.length > 10) {
				this.$message({
					message: '顺序最大长度为10',
					type: 'error',
					duration: 4000,
				})
				return
			}
			if (params.demo.length > 80) {
				this.$message({
					message: '备注最大长度为80',
					type: 'error',
					duration: 4000,
				})
				return
			}
			if (this.handleType == 1) {
				var url = '/p/injuryset/add'
			} else {
				var url = '/p/injuryset/update'
			}
			const self = this
			params.type = this.activeName
			this.$axios.post(url, this.$qs(params)).then(res => {
				if (res.data.code == 0) {
					this.$message({
						message: res.data.message,
						type: 'success',
					})
					self.getData(1)
					self.dialogVisible = false
				} else {
					this.$message({
						message: res.data.message,
						type: 'error',
					})
				}
			})
		},
		formatDate(nows) {
			if (nows == null || nows == '') {
				return ''
			}
			nows = nows * 1000
			var now = new Date(nows)
			var year = now.getFullYear()
			var month = now.getMonth() + 1
			if (month.toString().length < 2) {
				month = '0' + month
			}
			var date = now.getDate()
			if (date.toString().length < 2) {
				date = '0' + date
			}
			var hour = now.getHours()
			if (hour.toString().length < 2) {
				hour = '0' + hour
			}
			var minute = now.getMinutes()
			if (minute.toString().length < 2) {
				minute = '0' + minute
			}
			var second = now.getSeconds()
			if (second.toString().length < 2) {
				second = '0' + second
			}
			return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
		},
		getTabList() {
			let url = '/p/menu/getChildrenMenu'
			let params = {}
			let that = this
			this.$axios.post(url, this.$qs(params)).then(res => {
				if (res.data.code == 0) {
					that.tabList = res.data.data
				}
			})
		},

		delTableData(data) {
			//删除数据
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				closeOnClickModal: false,
				cancelButtonClass: '',
				center: true,
			}).then(() => {
				let url = '/p/analyse/delete'
				let params = {
					uuid_str: data.uuid,
				}
				this.$axios.post(url, this.$qs(params)).then(res => {
					if (res.data.code == 0) {
						this.$message({
							message: res.data.message,
							type: 'success',
						})

						this.total = this.total - 1
						this.getPageSize()

						this.getData(this.page)
					} else {
						this.$message({
							message: res.data.message,
							type: 'error',
						})
					}
					// that.getData();
				})
			})
		},
		//新增表单弹窗
		postAdd() {
			this.handleType = 1
			this.formAdd = { ...this.formAddSet }
			this.dialogVisible = true
			this.addMenuTitle = '新增伤病部位'
		},
		postLook(data) {
			sessionStorage.setItem('arch_search', JSON.stringify({ date: this.date, deparmentId: this.deparmentId, staffValue: this.staffValue, keyword: this.keyword, page: this.page }))

			this.$router.push({
				path: '/analysis/reportManagementLook',
				query: {
					uuid: data.uuid,
					page: this.page,
				},
			})
		},
		ChangeState(data) {
			let url = '/p/injuryset/able'
			let params = {
				uuid: data.uuid,
			}
			let that = this
			this.$axios.post(url, this.$qs(params)).then(res => {
				if (res.data.code == 0) {
					this.$message({
						// message:response.data.message,
						message: res.data.message,
						type: 'success',
					})
				} else {
					this.$message({
						type: 'error',
						message: res.data.message,
					})
				}
				that.getData()
			})
		},
		someDels() {
			let that = this
			//删除数据
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				closeOnClickModal: false,
				cancelButtonClass: '',
				center: true,
			}).then(() => {
				let params = []
				let url = '/p/analyse/delete'
				if (that.sels.length == 0) {
					that.$message({
						message: '请选择要删除的数据',
						type: 'error',
						duration: 4000,
					})
					return
				}
				for (let i = 0; i < this.sels.length; i++) {
					params[i] = this.sels[i].uuid
				}
				let ids = params.join(',')
				let data = { uuid_str: ids }
				this.$axios.post(url, this.$qs(data)).then(res => {
					// that.getData();
					if (res.data.code == 0) {
						this.$message({
							message: res.data.message,
							type: 'success',
						})

						this.total = this.total - that.sels.length
						this.getPageSize()

						this.getData(this.page)
					} else {
						this.$message({
							message: res.data.message,
							type: 'error',
						})
					}
				})
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.child-top {
	margin-top: 10px;
	.input-from {
		float: left;
		// min-width: 600px;
		margin-bottom: 0;

		.searchBtn {
			margin-top: 20px;
		}

		& > .el-select {
			width: 150px;
			margin-top: 20px;

			.el-input__inner {
				border-radius: 0;
				border-right: transparent;
			}

			.el-input__inner:focus {
				border-right: 1px solid #409eff;
			}
		}

		.el-select,
		.el-input {
			margin-top: 20px;
		}

		.el-select:nth-child(4) {
			.el-input__inner:focus {
				border-right: 0.052083rem solid #409eff;
			}
		}

		.el-select:nth-child(5) {
			.el-input__inner {
				border-right: 0;
			}

			.el-input__inner:focus {
				border-right: 0.052083rem solid #409eff;
			}
		}

		.el-select:nth-child(6) {
			width: 120px;
			margin-left: 20px;

			.el-input__inner {
				border-radius: 0px 4px 4px 0;
			}
		}

		.el-select:nth-child(1) {
			.el-input__inner {
				border-radius: 4px 0 0 4px;
			}
		}

		.el-input.el-input--suffix.left-child-input {
			// float: left;
			width: 200px;

			.el-input__inner {
				border-radius: 0 4px 4px 0;
			}
		}
	}

	.operation-button {
		// float: left;
		margin-bottom: 10px;
	}
}
.ModifyDoalog {
	margin-top: -20px;
	padding: 0 0 0 20px;
	& > p {
		font-size: 20px;
		color: #3b3b3b;
		text-align: center;
		margin-bottom: 20px;
	}
	.el-form {
		padding-right: 30px;
		.form_inRact {
			display: flex;
			margin-bottom: 20px;
			.el-form-item:first-child {
				margin-right: 5%;
			}
		}
	}
}
.table-pagination {
	text-align: right;
	margin-top: 10px;
	/deep/ span {
		float: left;
	}
}
.newTable {
	height: 400px;
	overflow: auto;
	.el-table {
		width: 40%;
	}
}

/deep/ input::-webkit-input-placeholder {
	color: #999;
}
/deep/ input::-moz-input-placeholder {
	color: #999;
}
/deep/ input::-ms-input-placeholder {
	color: #999;
}

.iconshanchu-moren {
	&:hover {
		color: #f00;
		cursor: pointer;
	}
}

.el-pagination {
	background: #fff;
	padding: 10px;
	margin-top: 0;
}
.child-top .operation-button .del_btn:hover {
	background: #eb1e44;
	color: #ffffff;
}
</style>
